import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const KontaktPage = () => (
  <Layout>
    <div class="page-narrow">
      <SEO title="Kontakt" />
      <h1 className="heading" hidden>
        Kontakt
      </h1>
      <address
        class="vcard"
        itemscope
        itemtype="http://microformats.org/profile/hcard"
      >
        <p class="adr-block">
          <span className="adr-line">Volker Sattel</span>
          <span className="adr-line">Sebastian Fessel</span>
          <span className="adr-line">Katia Fouquet</span>
        </p>
        <p class="adr adr-block">
          <span class="adr-line">
            <a itemprop="tel" href="tel:+493041717570" class="adr-phone">
              +49 (0)30 41 71 75 70
            </a>
          </span>
          <span class="adr-line">
            <a
              itemprop="email"
              href="mailto:locations@gmx.de"
              class="adr-email"
            >
              locations@gmx.de
            </a>
          </span>
          <span class="adr-line">
            <a
              itemprop="website"
              href="http://www.locationbüro.de"
              class="adr-web"
            >
              www.locationbüro.de
            </a>
          </span>
        </p>
        <p class="adr adr-block">
          <span className="adr-line">Standort Mitte</span>
          <span className="adr-line">
            <span class="street-address" itemprop="street-address">
              Steinstr. 27
            </span>
          </span>
          <span className="adr-line">
            <span class="postal-code">10119</span>
            <span class="locality">Berlin</span>
          </span>
        </p>
        <p class="adr adr-block">
          <span className="adr-line">Standort Kreuzberg</span>
          <span className="adr-line">
            <span class="street-address" itemprop="street-address">
              Oranienstr. 57
            </span>
          </span>
          <span className="adr-line">
            <span class="postal-code">10969</span>
            <span class="locality">Berlin</span>
          </span>
        </p>
      </address>
    </div>
  </Layout>
)

export default KontaktPage
